import React, { useEffect, useRef } from 'react'
import { lazyInitTrustpilotWidget } from './lib/trustpilotScript'
import {
	FionaTrustpilotBusinessID,
	TrustpilotWidgetConfigs,
	TrustpilotWidgetType
} from 'components/vendor/TrustpilotWidget/lib/trustpilotConfig'
import useIsElementInView from '../../../lib/hooks/useIsElementInView'
import { VoidCallback } from 'types/index'
import { noOp } from 'lib'
import styled from '@emotion/styled'

interface Props {
	widget: TrustpilotWidgetType
	ratingsVisible?: 'all' | 'onlyGood'
	theme?: 'light' | 'dark'
}

export const TrustpilotWidget = ({ widget, ratingsVisible = 'onlyGood', theme = 'light' }: Props): JSX.Element => {
	const loadFn = useRef<VoidCallback>(() => {
		// do nothing
	})
	const ref = useIsElementInView(() => {
		loadFn.current()
	})
	useEffect(() => {
		loadFn.current = () => {
			lazyInitTrustpilotWidget(ref.current)
			loadFn.current = noOp
		}
	})

	const stars = ratingsVisible === 'all' ? '1,2,3,4,5' : '4,5'
	const config = TrustpilotWidgetConfigs[widget]

	return (
		<div
			ref={ref}
			style={{ height: config.height, width: '100%' }}
			className="trustpilot-widget"
			data-locale="en-US"
			data-businessunit-id={FionaTrustpilotBusinessID}
			data-template-id={config.templateId}
			data-style-height={config.height}
			data-style-width="100%"
			data-theme={theme}
			data-stars={stars}
			data-review-languages="en"
			data-font-family="Nunito"
		>
			<TrustpilotLink href="https://www.trustpilot.com/review/fiona.com" target="_blank" rel="noopener noreferrer">
				Read Fiona Reviews On Trustpilot.com
			</TrustpilotLink>
		</div>
	)
}

// Trustpilot iframe padding
const TrustpilotLink = styled.a`
	padding-left: 16px;
	padding-right: 18px;
`

import { ReactNode } from 'react'
import { MetaTagsInterface } from 'lib/routing/MetaTags'
import { CustomHead } from '../CustomHead'
import { SchemaOrgItem } from '../CustomHead/Schemas/SchemaWrapper'
import { Footer } from 'shared/layout/Footer'
import { Header } from 'shared/layout/Header'

interface Props {
	metaTags: MetaTagsInterface
	schemas?: Array<SchemaOrgItem>
	children: ReactNode
}

export const StandardLayout = ({ metaTags, children, schemas }: Props) => (
	<>
		<CustomHead tags={metaTags} schemas={schemas} />
		<Header />
		{children}
		<Footer />
	</>
)

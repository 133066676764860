import { isBrowser } from 'lib'
import { TrustpilotError, TrustpilotScriptAlreadyExistsError } from 'components/vendor/TrustpilotWidget/lib/errors'
import { NotInBrowserError } from 'lib/errors'
import { addDDError } from 'services/AnalyticsLite/Datadog/events'

const TRUSTPILOT_SCRIPT_ID = 'trustpilot-iframe-helper'
const TRUSTPILOT_SCRIPT_SRC = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'

export interface Trustpilot {
	loadFromElement: (element: Element, forceReload: boolean) => void
}

export const lazyInitTrustpilotWidget = (element: HTMLElement) => {
	if (element && isBrowser() && window.Trustpilot) {
		try {
			window.Trustpilot.loadFromElement(element, false)
		} catch (err) {
			if (!(err instanceof Error)) {
				err = new TrustpilotError('Error Loading From Element')
			}
			addDDError(err)
		}
	} else {
		// If the script hasnt loaded yet (window.Trustpilot is null) then we can just let the script init the widgets, no callback needed
		loadTrustpilotHelperScript().catch(error => {
			if (!(error instanceof TrustpilotScriptAlreadyExistsError)) {
				addDDError(error)
			}
		})
	}
}

const loadTrustpilotHelperScript = () =>
	new Promise<void>((resolve, reject) => {
		if (isBrowser()) {
			if (document.getElementById(TRUSTPILOT_SCRIPT_ID) === null) {
				const helper = document.createElement('script')
				helper.id = TRUSTPILOT_SCRIPT_ID
				helper.src = TRUSTPILOT_SCRIPT_SRC
				helper.async = true
				helper.defer = true
				helper.setAttribute('importance', 'low')
				helper.onload = () => {
					resolve()
				}
				helper.onerror = (event, source, line, col, error) => reject(error || event)
				document.head.appendChild(helper)
			} else {
				reject(new TrustpilotScriptAlreadyExistsError())
			}
		} else {
			reject(new NotInBrowserError())
		}
	})

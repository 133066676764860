import { ComponentProps, useState } from 'react'
import { Actionable, Hidden, Icon, View } from 'reshaped/bundle'
import { Container } from '../Container'
import { NavContextProvider } from './NavContext'
import { useAuthState } from 'services/Authentication'
import { DesktopMenu } from './DesktopMenu'
import { FionaLogo } from '../../icons/FionaLogo'
import { MobileMenu } from './MobileMenu'
import { MenuIcon } from './Icons'
import dynamic from 'next/dynamic'

const MobileNav = dynamic<ComponentProps<typeof MobileMenu>>(
	() => import('./MobileMenu').then(module => module.MobileMenu),
	{ ssr: false }
)

const zIndexRecord: Record<string, number> = {
	header: 70
}

export const Header = () => {
	const { userType } = useAuthState()
	const showNativeLoans = true
	const [menuOpened, setMenuOpened] = useState(false)

	return (
		<NavContextProvider
			value={{
				userStatus: userType,
				nativeFeatureFlag: showNativeLoans
			}}
		>
			<View
				direction="row"
				align="center"
				backgroundColor="primary"
				width="100%"
				height="80px"
				zIndex={zIndexRecord.header}
				position="fixed"
				attributes={{
					style: {
						backgroundImage: 'linear-gradient(93deg, rgb(89, 43, 134) 7%, rgb(59, 28, 90) 103%)',
						top: 0,
						left: 0
					}
				}}
			>
				<Container>
					<View
						direction="row"
						align="center"
						attributes={{
							style: {
								justifyContent: 'space-between'
							}
						}}
					>
						<View direction="row" align="center" gap={4}>
							<Hidden hide={{ s: false, l: true }}>
								<View align="center">
									<Actionable onClick={() => setMenuOpened(!menuOpened)}>
										<Icon svg={MenuIcon} size={8} />
									</Actionable>
									<MobileNav onClose={() => setMenuOpened(false)} isMenuOpen={menuOpened} />
								</View>
							</Hidden>
							<View width={{ s: '64px', m: '94px' }}>
								<FionaLogo />
							</View>
						</View>
						<Hidden hide={{ s: true, l: false }}>
							<DesktopMenu />
						</Hidden>
					</View>
				</Container>
			</View>
			<View
				height="80px"
				attributes={{
					style: {
						backgroundImage: 'linear-gradient(93deg, rgb(89, 43, 134) 7%, rgb(59, 28, 90) 103%)'
					}
				}}
			/>
		</NavContextProvider>
	)
}

export class TrustpilotError extends Error {
	constructor(message: string) {
		super('Trustpilot Error: ' + message)
		this.name = 'TrustpilotError'
	}
}

export class TrustpilotScriptAlreadyExistsError extends TrustpilotError {
	constructor() {
		super('Failed to load Trustpilot script since it already exists')
		this.name = 'TrustpilotScriptAlreadyExists'
	}
}

import { FionaLogo } from 'shared/icons/FionaLogo'
import { Icon, Text, View } from 'reshaped/bundle'
import { Link } from 'ui/reshaped/Link'
import { EqualHousingOpportunityIcon } from './assets/EqualHousingOpportunityIcon'

const CURRENT_YEAR = new Date().getFullYear()

export const SecondaryLinks = () => {
	return (
		<View gap={6}>
			<View
				gap={4}
				direction={{ m: 'row' }}
				attributes={{ style: { justifyContent: 'space-between' } }}
				align={{ s: 'start', m: 'center' }}
			>
				<View gap={{ s: 3, m: 4 }} direction={{ m: 'row' }} align={{ s: 'start', m: 'center' }}>
					<View width={15}>
						<FionaLogo />
					</View>
					<Text variant="body-3">Copyright © {CURRENT_YEAR} ML Enterprise Inc</Text>
				</View>
				<View direction={{ m: 'row' }} gap={{ s: 4, m: 2 }} align={{ s: 'start', m: 'center' }}>
					<Link href="/disclosure">Disclosure</Link>
					<Link href="/privacy">Privacy Policy</Link>
					<Link href="/terms">Terms of Service</Link>
					<Link href="https://cms.engine.onevenfinancial.com/assets/d9630a9e-5b17-4211-8fcc-398cd9c7d950?download=true">
						Financial Privacy Notice
					</Link>
					<View direction="row">
						Powered by&nbsp;
						<Link href="https://engine.tech" attributes={{ target: '_blank', rel: 'noopener noreferrer' }}>
							Engine
						</Link>
					</View>

					<Icon svg={EqualHousingOpportunityIcon} size={10} />
				</View>
			</View>

			<Text align="center" variant="body-3">
				ML Enterprise Inc. (formerly Even Financial Inc.) NMLS#&nbsp;1475872 /{' '}
				<Link href="https://nmlsconsumeraccess.org/" attributes={{ target: '_blank', rel: 'noopener noreferrer' }}>
					NMLS&nbsp;Consumer&nbsp;Access
				</Link>
			</Text>

			<Text align={{ s: 'center', m: 'start' }} variant="body-3">
				This site is not authorized by the New York State Department of Financial Services. No mortgage solicitation
				activity or loan applications for properties located in the State of New York can be facilitated through this
				site.
			</Text>
		</View>
	)
}

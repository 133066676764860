import React, { CSSProperties } from 'react'
import { BlockComponent } from 'lib'
import { ReactInlineSvg, ReactInlineSvgProps } from 'components/vendor/ReactInlineSvg'
import styled from '@emotion/styled'
import { DomainTheme, DomainThemeProvider } from 'components/patterns/themes/context/ThemeProvider'
import { Asset } from 'services/ContentfulService/models/asset'
import { addDDError } from 'services/AnalyticsLite/Datadog/events'

interface BaseProps {
	title?: string
	width?: CSSProperties['width']
	height?: CSSProperties['height']
	theme?: DomainTheme
	alt?: string
	color?: string
	loader?: JSX.Element
}

//TODO: Should probably make a generic for union types
export type InlineSvgProps =
	| ({ src: string } & { asset?: never } & BaseProps)
	| ({ src?: string } & { asset: Asset } & BaseProps)

const decodeSrcIfEmbedded = (src: string) => {
	try {
		const dataURI = src.match(/data:image\/svg[^,]*?(;base64)?,(.*)/)
		if (dataURI) {
			return dataURI[1] ? atob(dataURI[2]) : decodeURIComponent(dataURI[2])
		} else if (src.indexOf('<svg') >= 0) {
			return src
		}
	} catch (err) {
		addDDError(err, { src })
	}
}

const SSRInlineSvg: BlockComponent<ReactInlineSvgProps> = props => {
	const embeddedSrc = decodeSrcIfEmbedded(props.src)
	if (embeddedSrc) {
		const parsedSrc = embeddedSrc.match(/^<svg([^>]+)>((?:.)*)<\/svg>$/)
		if (parsedSrc) {
			const svgParams = parsedSrc[1]
			const parsedViewBox = svgParams.match(/viewBox=(?:['"])([\s0-9\.]+)(?:['"])/)
			const viewBox = (parsedViewBox && parsedViewBox[1]) || undefined
			const children = parsedSrc[2]
			return (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox={viewBox}
					className={props.className}
					height={props.height || '100%'}
					width={props.width || '100%'}
					dangerouslySetInnerHTML={{ __html: children }}
				/>
			)
		}
	}
	return <ReactInlineSvg {...props} />
}

/**
 * Support Assets
 */
const processProps = (props: InlineSvgProps): ReactInlineSvgProps => {
	if (props.asset) return { ...props, src: props.asset.url, title: props.alt || props.asset.title }
	if (typeof props.src === 'string') return props
	if (props.src?.['src']) return { ...props, src: props.src?.['src'] }
	return props
}

export const InlineSvg: BlockComponent<InlineSvgProps> = ({ theme, ...props }) => {
	if (theme) {
		return (
			<DomainThemeProvider theme={theme}>
				<ThemedSvg>
					<SSRInlineSvg {...processProps(props)} />
				</ThemedSvg>
			</DomainThemeProvider>
		)
	}
	return <SSRInlineSvg {...processProps(props)} />
}

const ThemedSvg = styled.div`
	.svg-secondary {
		fill: ${props => props.theme.icon.secondaryFill};
	}
`

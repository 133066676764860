import { PrimaryLinks } from './PrimaryLinks'
import { SecondaryLinks } from './SecondaryLinks'
import { Container, Divider, View } from 'reshaped/bundle'

export const Footer = () => {
	return (
		<View
			backgroundColor="primary"
			paddingBlock={10}
			attributes={{ style: { background: 'linear-gradient(93deg, #592b86 7%, #3b1c5a 103%)' } }}
		>
			<Container width="1280px">
				<View gap={10}>
					<PrimaryLinks />
					<Divider />
					<SecondaryLinks />
				</View>
			</Container>
		</View>
	)
}

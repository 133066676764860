import { createContext, PropsWithChildren } from 'react'
import { UserType } from 'services/Authentication'
import { useHasMounted } from '../../../lib/renderWrappers/ClientOnly'

export type Device = 'mobile' | 'desktop'
export interface NavContext {
	userStatus: UserType
	nativeFeatureFlag: boolean
}

const DefaultNavContext: NavContext = {
	userStatus: 'anonymous',
	nativeFeatureFlag: false
}

export const NavContext = createContext<NavContext>(DefaultNavContext)

type NavContextProps = { value: NavContext }

export const NavContextProvider = ({ value, children }: PropsWithChildren<NavContextProps>) => {
	const hasMounted = useHasMounted()
	const mergedValue = hasMounted ? { ...DefaultNavContext, ...value } : DefaultNavContext
	return <NavContext.Provider value={mergedValue}>{children}</NavContext.Provider>
}

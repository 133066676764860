import { Link as ReshapedLink, LinkProps, Text, TextProps } from 'reshaped/bundle'
import NextLink from 'next/link'
import { Url } from 'url'

type Props = {
	href: string | Url
	textVariant?: TextProps['variant']
	weight?: TextProps['weight']
	color?: TextProps['color']
} & Omit<LinkProps, 'color'>

export const Link = ({ href, color, textVariant, weight, ...props }: Props) => {
	return (
		<Text color={color} variant={textVariant} weight={weight}>
			<NextLink href={href} passHref legacyBehavior>
				<ReshapedLink {...props} color="inherit">
					{props.children}
				</ReshapedLink>
			</NextLink>
		</Text>
	)
}

import { defaultIsActive, SmartLink } from '../SmartLink'
import { NavNode, PrimaryNavElements } from '../const'
import { useRouter } from 'next/router'
import { Icon, Link, Popover, Text, View } from 'reshaped/bundle'
import { ChevronIcon, ExternalIcon } from '../Icons'

type Props = { node: NavNode }

const MenuItem = ({ node }: Props) => {
	const router = useRouter()
	const { external, children, name, isActive } = node
	const active = isActive ? isActive(router.asPath, node) : defaultIsActive(router.asPath, node)

	if (children) {
		return (
			<Popover triggerType="hover" padding={1}>
				<Popover.Trigger>
					{attributes => (
						<SmartLink node={node}>
							<Link variant="plain" color="inherit" attributes={attributes}>
								<Text variant="body-2" color={active ? 'positive' : undefined} weight={active ? 'bold' : 'regular'}>
									<View direction="row" align="center">
										{name} <Icon svg={ChevronIcon} size={4} />
									</View>
								</Text>
							</Link>
						</SmartLink>
					)}
				</Popover.Trigger>
				<Popover.Content>
					{children
						.filter(navNode => navNode.hideByDevice !== 'desktop')
						.map(navNode => (
							<SmartLink node={navNode} key={navNode.key}>
								<Link variant="plain" color="inherit">
									<Text variant="body-3">
										<View direction="row" align="center" gap={1} paddingBlock={2} paddingInline={4}>
											{navNode.name} {navNode.external && <Icon svg={ExternalIcon} size={4} />}
										</View>
									</Text>
								</Link>
							</SmartLink>
						))}
				</Popover.Content>
			</Popover>
		)
	}

	return (
		<SmartLink node={node}>
			<Link variant="plain" color="inherit">
				<Text variant="body-2" color={active ? 'positive' : undefined} weight={active ? 'bold' : 'regular'}>
					<View direction="row" align="center" gap={1}>
						{name} {external && <Icon svg={ExternalIcon} size={4} />} {children && <Icon svg={ChevronIcon} size={4} />}
					</View>
				</Text>
			</Link>
		</SmartLink>
	)
}

export const DesktopMenu = () => (
	<View direction="row" gap={6} align="center">
		{PrimaryNavElements.map(navNode => (
			<MenuItem key={navNode.key} node={navNode} />
		))}
	</View>
)

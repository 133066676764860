export const ExternalIcon = () => {
	return (
		<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.5002 3.83329C12.04 3.83329 11.6669 3.4602 11.6669 2.99996C11.6669 2.53972 12.04 2.16663 12.5002 2.16663H17.5002C17.9604 2.16663 18.3335 2.53972 18.3335 2.99996V7.99996C18.3335 8.4602 17.9604 8.83329 17.5002 8.83329C17.04 8.83329 16.6669 8.4602 16.6669 7.99996V5.0118L8.92279 12.7559C8.59736 13.0813 8.06972 13.0813 7.74428 12.7559C7.41884 12.4304 7.41884 11.9028 7.74428 11.5774L15.4884 3.83329H12.5002ZM4.16687 6.33329C3.94586 6.33329 3.73389 6.42109 3.57761 6.57737C3.42133 6.73365 3.33354 6.94561 3.33354 7.16663V16.3333C3.33354 16.5543 3.42133 16.7663 3.57761 16.9225C3.7339 17.0788 3.94586 17.1666 4.16687 17.1666H13.3335C13.5546 17.1666 13.7665 17.0788 13.9228 16.9225C14.0791 16.7663 14.1669 16.5543 14.1669 16.3333V11.3333C14.1669 10.8731 14.54 10.5 15.0002 10.5C15.4604 10.5 15.8335 10.8731 15.8335 11.3333V16.3333C15.8335 16.9963 15.5701 17.6322 15.1013 18.1011C14.6325 18.5699 13.9966 18.8333 13.3335 18.8333H4.16687C3.50383 18.8333 2.86794 18.5699 2.3991 18.1011C1.93026 17.6322 1.66687 16.9963 1.66687 16.3333V7.16663C1.66687 6.50358 1.93026 5.8677 2.3991 5.39886C2.86794 4.93002 3.50383 4.66663 4.16687 4.66663H9.16687C9.62711 4.66663 10.0002 5.03972 10.0002 5.49996C10.0002 5.9602 9.62711 6.33329 9.16687 6.33329H4.16687Z"
				fill="white"
			/>
		</svg>
	)
}

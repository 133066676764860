import { Icon, Text, View } from 'reshaped/bundle'
import { ExternalIcon } from './assets/ExternalIcon'
import { Link } from 'ui/reshaped/Link'

interface ColumnLink {
	text: string
	href: string
	external?: boolean
}

interface Props {
	title: string
	links: Array<ColumnLink>
}

export const Column = ({ title, links }: Props) => {
	return (
		<View gap={6}>
			<Text variant="body-3" weight="bold" attributes={{ style: { textTransform: 'uppercase' } }}>
				{title}
			</Text>
			<View gap={4}>
				{links.map(link => {
					return (
						<Link
							key={link.href}
							href={link.href}
							variant="plain"
							attributes={{
								target: link.external ? '_blank' : undefined,
								rel: link.external ? 'noopener noreferrer' : undefined
							}}
						>
							<View direction="row" gap={1} align="center">
								{link.text}
								{link.external && <Icon svg={ExternalIcon} />}
							</View>
						</Link>
					)
				})}
			</View>
		</View>
	)
}

/* eslint-disable @typescript-eslint/ban-ts-comment */
import { RefObject, useEffect, useRef } from 'react'
import { isBrowser } from 'lib/index'

const isUnsupported =
	isBrowser() &&
	(!('IntersectionObserver' in window) ||
		!('IntersectionObserverEntry' in window) ||
		!('intersectionRatio' in window.IntersectionObserverEntry.prototype))

// eslint-disable-next-line sonarjs/cognitive-complexity
const useIsElementInView = (callback: () => void | undefined): RefObject<any> => {
	const ref = useRef()
	const callbackRef = useRef()
	useEffect(() => {
		const node = ref.current
		const options = {
			root: null,
			rootMargin: '0px 0px 0px 0px',
			threshold: 0
		}
		const observer = new IntersectionObserver((entries): void => {
			entries.forEach(entry => {
				if (entry.isIntersecting && ref.current && callbackRef.current) {
					// @ts-ignore
					callbackRef.current(callback)
				}
			})
		}, options)

		if (node) {
			observer.observe(node)
		}

		return (): void => observer.disconnect()
	}, [callback])

	// set current callback in ref, before second useEffect uses it
	useEffect(() => {
		// useEffect wrapper to be safe for concurrent mode
		// @ts-ignore
		callbackRef.current = callback
	})

	if (typeof window === 'undefined') {
		return ref
	}

	if (isUnsupported && callbackRef.current) {
		// @ts-ignore
		callbackRef.current(callback)
	}

	return ref
}

export default useIsElementInView

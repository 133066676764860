export const FionaTrustpilotBusinessID = '5fb2c5248c265c0001186720'

export type TrustpilotWidgetType = 'list' | 'slider' | 'microStar' | 'carousel' | 'mini'

interface TrustpilotConfig {
	height: number
	templateId: string
}

export const TrustpilotWidgetConfigs: Record<TrustpilotWidgetType, TrustpilotConfig> = {
	list: { height: 600, templateId: '539ad60defb9600b94d7df2c' },
	slider: { height: 250, templateId: '54ad5defc6454f065c28af8b' },
	microStar: { height: 20, templateId: '5419b732fbfb950b10de65e5' },
	carousel: { height: 130, templateId: '53aa8912dec7e10d38f59f36' },
	mini: { height: 70, templateId: '53aa8807dec7e10d38f59f32' }
}

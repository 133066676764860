import { withInlineSVG } from 'components/patterns/icons/svg-tools/generator/withInlineSVG'

import creditScoreAsset from './assets/credit-score.svg'
import savingsPrimaryIcon from './assets/savings.svg'
import creditCardPrimaryIcon from './assets/credit-cards.svg'
import insuranceAsset from './assets/insurance.svg'
import personalLoansAsset from './assets/personal-loans.svg'
import lifeInsuranceAsset from './assets/life-insurance.svg'
import studentLoanRefinanceAsset from './assets/student-loan-refinance.svg'
import mortgageAsset from './assets/mortgages.svg'

export const CreditScoreIcon = withInlineSVG(creditScoreAsset)

export const SavingsIcon = withInlineSVG(savingsPrimaryIcon)

export const CreditCardsIcon = withInlineSVG(creditCardPrimaryIcon)

export const InsuranceIcon = withInlineSVG(insuranceAsset)

export const PersonalLoansIcon = withInlineSVG(personalLoansAsset)

export const LifeInsuranceIcon = withInlineSVG(lifeInsuranceAsset)

export const StudentLoanRefinanceIcon = withInlineSVG(studentLoanRefinanceAsset)

export const MortgageIcon = withInlineSVG(mortgageAsset)

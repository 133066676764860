import trustmark from './trustedSite.svg'
import Image from 'next/image'

export const TrustedSiteMark = () => <Image alt="TrustedSite" src={trustmark} height={30} width={80} />
export const TrustedSite = () => (
	<a href={TrustedSiteURL} target="_blank" rel="noreferrer">
		<TrustedSiteMark />
	</a>
)
export const TrustedSiteURL = 'https://www.trustedsite.com/verify?host=fiona.com'

import React from 'react'
import { InlineSvg, InlineSvgProps } from 'components/patterns/icons/svg-tools/InlineSvg'
import styled from '@emotion/styled'
import { css, SerializedStyles } from '@emotion/react'
import { TextMods } from 'components/styles/primitives/modifiers/text/textMods'

export const withInlineSVG =
	(source: string, options: Partial<InlineSvgProps> = {}) =>
	(props: Partial<InlineSvgProps>) => <InlineSvg {...{ ...options, ...props }} src={source} asset={undefined} />

export const withIconSVG = (source: string, styles?: SerializedStyles) =>
	styled(withInlineSVG(source, { width: '0.75em', height: '0.75em' }))(
		css(TextMods({ verticalAlign: 'baseline' }), styles)
	)

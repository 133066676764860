import Link from 'next/link'
import { PropsWithChildren, useContext } from 'react'
import { NavNode } from './const'
import { NavContext } from './NavContext'

const buildUrl = (href: string, parameters: string | undefined): string => href + (parameters ? `?${parameters}` : '')

interface SmartLinkProps {
	node: NavNode
	passHref?: boolean
}

export const SmartLink = ({ node, passHref = true, children }: PropsWithChildren<SmartLinkProps>): JSX.Element => {
	const context = useContext(NavContext)
	if (node.visible && !node.visible(context)) {
		return <></>
	}
	let href = buildUrl(node.href, node.parameters)
	let external = node.external || false
	node.alternatives?.forEach(alt => {
		if (alt.condition(context)) {
			external = alt.external || false
			href = buildUrl(alt.href, alt.parameters)
		}
	})

	let linkProps = { href, prefetch: false, target: '_self', rel: '', legacyBehavior: true, passHref }
	if (external) linkProps = { ...linkProps, target: '_blank', rel: 'noreferrer nofollow' }

	return <Link {...linkProps}>{children}</Link>
}

const getAllHrefs = (node: NavNode): string[] => {
	const altPaths = node.alternatives?.map(alt => alt.href) || []
	const childPaths = []
	if (node.children) {
		childPaths.push(
			...node.children?.map(child => getAllHrefs(child))?.reduce((accum, value) => [...accum, ...value], [])
		)
	}
	return [node.href, ...childPaths, ...altPaths]
}

export const defaultIsActive = (path: string, node: NavNode) => {
	const childTree = getAllHrefs(node)
	const anyMatch = childTree.some(href => path.indexOf(href) > -1)
	const notLearn = path.indexOf('learn') === -1
	return notLearn ? anyMatch : false
}
